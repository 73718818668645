<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row justify="end">
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Wyszukaj"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col>
        <v-switch
          v-model="show"
          @click="get_new_data()"
          inset
          :label="'Wyświetl: ' + (show?'Zamknięte':'Aktywne')"
          v-if="$store.state.user_permissions.project_archive"
        ></v-switch>
      </v-col>
      <v-col style="text-align: right">
        <v-btn
          color="secondary_dark"
          dark
          depressed
          tile
          to="/projekt-nowy"
          v-if="$store.state.user_permissions.project_create"
        >
          Dodaj projekt
        </v-btn>
      </v-col>
    </v-row>
    
    <v-data-table
      class="classic_table"
      :headers="headers"
      :items="list"
      :search="search"
      item-key="name"
      @click:row="projectClick"
      :footer-props="{
        itemsPerPageText : 'Elementów na stronie',
        itemsPerPageAllText : 'Wszystkie',
        itemsPerPageOptions : [15,45,90,-1],
      }"
    >
      <template v-slot:no-data>Brak danych</template>
      <template v-slot:no-results>Brak wyników</template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} z {{ itemsLength}}
      </template>
      <template
        v-slot:item.progress="{ item }"
      >
        <v-progress-circular
          :value="item.progress" 
          :rotate="-90"
          color="primary"
        ></v-progress-circular>
      </template>
    </v-data-table>

  </div>
</template>

<script>
export default {
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista projektów',
        disabled: true,
        to: '/projekty',
      },
    ],

    headers: [
      {
        text: 'Nazwa projektu',
        align: 'start',
        value: 'name',
      },
      { text: 'Klient', value: 'assign_user_name' },
      { text: 'Postęp', value: 'progress' },
      { text: 'Data rozpoczęcia', value: 'created_at' },
      { text: 'Data zakończenia', value: 'finished_at' },
      { text: 'Dni do końca', value: 'finish' }
    ],

    list: [
      {
        id: 1,
        done: false,
        name: 'Nazwa Klienta',
        finished_at: 'Nazwa Klienta',
        full_name: 'Pełna nazwa',
        contact_person: 'Imię Nazwisko',
        nip: '14012021',
        number: '12313131233'
      },
    ],
    
    show: false,
    search: ''
  }),
  methods: {
    projectClick(value) {
      if(this.$store.state.user_permissions.project_show)
        this.$router.push('/projekt/'+value.id);
    },
    get_new_data(){
      this.$store.commit('loader');
      // Pobieranie zamknietych
      if(this.show) {
        this.$axios({url: this.$store.state.api +'/project_archive', data: {}, method: 'GET' })
          .then(resp => {
            this.list = resp.data;
            console.log(resp.data);
            this.$store.commit('loader_off');
          })
          .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      }
      else {
        this.$axios({url: this.$store.state.api +'/project', data: {}, method: 'GET' })
          .then(resp => {
            this.list = resp.data;
            console.log(resp.data);
            this.$store.commit('loader_off');
          })
          .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      }
    }
  },
  mounted(){
    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/project', data: {}, method: 'GET' })
      .then(resp => {
        this.list = resp.data;
        console.log(resp.data);
        this.$store.commit('loader_off');
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.classic_table {
  margin-top: 25px;
}
</style>